export const HomeObjOne = {
    id: "about",
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: "About me",
    headLine: "Introduction",
    description: `Hi there, I'm Mike van der Caaij, a ${Math.abs(
        new Date(Date.now() - new Date(2000, 7, 18).getTime()).getUTCFullYear() - 1970
    )}-year-old student at Avans University of Applied Sciences, pursuing my passion to become a Fullstack developer. I'm putting in a lot of effort and dedication to achieve my career objective.`,
    buttonLabel: "Button",
    imgStart: false,
    img: require("../../images/svg-9.svg"),
    alt: "introduction",
    dark: true,
    primary: true,
    darkText: true,
    displayBtn: false,
    href: "/",
};

export const HomeObjTwo = {
    id: "projects",
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: "Projects",
    headLine: "My projects",
    description: "Please click the button below to explore all of my publicly available projects!",
    buttonLabel: "Explore",
    imgStart: true,
    img: require("../../images/svg-6.svg"),
    alt: "project",
    dark: false,
    primary: false,
    darkText: true,
    href: "//github.com/mikevandercaaij?tab=repositories",
    displayBtn: true,
    target: "_blank",
};
