import React from "react";
import { animateScroll as scroll } from "react-scroll";
import {
    FooterContainer,
    FooterLink,
    FooterLinkItems,
    FooterLinksContainer,
    FooterLinkScroll,
    FooterLinksWrapper,
    FooterLinkTitle,
    FooterWrap,
    SocialLogo,
    SocialMedia,
    SocialMediaWrap,
    WebsiteRights,
} from "./FooterElements";

const Footer = () => {
    const toggleHome = () => {
        scroll.scrollToTop();
    };

    return (
        <FooterContainer>
            <FooterWrap>
                <FooterLinksContainer>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle>Navigation</FooterLinkTitle>
                            <FooterLinkScroll
                                to="about"
                                smooth={true}
                                duration={500}
                                spy={true}
                                exact="true"
                                offset={-80}
                            >
                                About me
                            </FooterLinkScroll>
                            <FooterLinkScroll
                                to="socials"
                                smooth={true}
                                duration={500}
                                spy={true}
                                exact="true"
                                offset={-80}
                            >
                                Socials
                            </FooterLinkScroll>
                            <FooterLinkScroll
                                to="projects"
                                smooth={true}
                                duration={500}
                                spy={true}
                                exact="true"
                                offset={-80}
                            >
                                Projects
                            </FooterLinkScroll>

                        </FooterLinkItems>
                        <FooterLinkItems>
                            <FooterLinkTitle>Socials</FooterLinkTitle>
                            <FooterLink href="//www.linkedin.com/in/mikevandercaaij">LinkedIn</FooterLink>
                            <FooterLink href="//www.github.com/mikevandercaaij/">GitHub</FooterLink>
                            <FooterLink href="//www.instagram.com/mike.vd.caaij/">Instagram</FooterLink>
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                </FooterLinksContainer>
                <SocialMedia>
                    <SocialMediaWrap>
                        <SocialLogo to="/" onClick={toggleHome}>
                            MVDC
                        </SocialLogo>
                        <WebsiteRights>&copy; {new Date().getFullYear()} All rights reserved.</WebsiteRights>
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>
        </FooterContainer>
    );
};

export default Footer;
